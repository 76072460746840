<template>
    <v-container fluid>
        <div class="white pa-3 rounded shadow1">
            <h1>Warranty</h1>
            <br><br>
            <div class="d-flex justify-center">
                <v-text-field
                    dense
                    outlined
                    hide-details
                    type="tedt"
                    v-model="serial"
                    placeholder="Enter imei/serial"
                    style="max-width: 400px;"
                ></v-text-field>
            </div>
            <!-- {{ $store.getters['sale/serialSales'] }} -->
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Serial/IMEI</th>
                        <th class="text-left">Supplier name</th>
                        <th class="text-left">Purchase date</th>

                        <th class="text-left">Customer code</th>
                        <th class="text-left">Customer name</th>
                        <th class="text-left">Customer email</th>
                        <th class="text-left">Customer Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in $store.getters['sale/serialSales']"
                        :key="item.id"
                    >
                        <td>{{ item.serial }}</td>
                        <td>{{ get(item, 'purchase.supplier.name') }}</td>
                        <td>{{ get(item, 'purchase.date') }}</td>
                        <td>{{ get(item, 'sales.customer.code') }}</td>
                        <td>{{ get(item, 'sales.customer.name') }}</td>
                        <td>{{ get(item, 'sales.customer.org_phone') }}</td>
                        <td>{{ get(item, 'sales.customer.email') }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </div>
    </v-container>
</template>

<script>
import useConfig from '../../composable/useConfig';

const { host, get } = useConfig();

export default {
    name: "Warranty",
    data: ()=> ({
        serial: '',
    }),
    computed: {
        customers() {
            let customer = this.$store.getters['customer/customers'];
            customer.unshift({
                id: null,
                display_text: "General Customer",
                type: 'G',
                name: '',
                phone: '',
                address: ''
            })
            return customer;
        },
        // serial() {
        //     this.serialSales({
        //         serial: this.serial
        //     });
        // },
    },
    watch: {
        serial(serial) {
            this.serialSales({
                serial: serial
            })
        }
    },
    async created() {
        // await this.$store.dispatch('sale/getSerialSales');
    },
    mounted() {
    },
    methods: {
        get: get,
        async serialSales(payload) {
            await this.$store.dispatch('sale/getSerialSales', payload);
        }
    },
}

</script>